import {
  Drawer,
  Flex,
  FormRow,
  FormStatus,
  Grid,
  Icon,
  InfoCard,
  Input,
  P,
  ProgressIndicator,
  Tag,
} from '@dnb/eufemia';
import { filter } from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import { deepCopy } from '@portals/shared/common/utils';
import { useCallback, useEffect, useState } from 'react';

import HeroPage from '@/components/HeroPage';
import { useBackend } from '@/hooks/useBackend';
import useSWRExpect404 from '@/hooks/useSWRExpect404';
import { EventWithUserDataResponse } from '@/openapi/schema';
import EventList from '@/pages/explorer/events/EventList';

import EventFilter from './EventFilter';

import style from './EventExplorerPage.module.css';

import ApiPageBanner from '@/illustrations/ApiPageBanner.png?url';

function sortEventsByFavorite(
  e1: EventWithUserDataResponse,
  e2: EventWithUserDataResponse,
): number {
  if (e1.isFavorite !== e2.isFavorite) {
    if (e1.isFavorite) {
      return -1;
    } else return 1;
  }
  return 0;
}

export default function EventExplorerPage() {
  const swrExpect404 = useSWRExpect404();
  const {
    data: events,
    isLoading,
    error,
    mutate,
  } = useBackend('/events', {}, swrExpect404);
  const { isLarge } = useMedia();

  const [sortedEvents, setSortedEvents] = useState<EventWithUserDataResponse[]>(
    [],
  );
  const [filteredEvents, setFilteredEvents] = useState<
    EventWithUserDataResponse[]
  >([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query);

  useEffect(() => {
    if (!events) return;
    const sortedByFavorite = events.sort(sortEventsByFavorite);
    setSortedEvents(sortedByFavorite);
  }, [events]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    const lowerCaseQuery = debouncedQuery.toLowerCase();
    const eventsFiltered = sortedEvents.filter((event) => {
      const matchesFilter =
        activeFilters.length === 0 ||
        activeFilters.includes(event.classification);

      if (!matchesFilter) return false;

      const matchesQuery = [event.name, event.slug].some(
        (field) => field && field.toLowerCase().includes(lowerCaseQuery),
      );

      return matchesQuery;
    });

    setFilteredEvents(eventsFiltered);
  }, [sortedEvents, debouncedQuery, activeFilters]);

  const onFavoriteChange = useCallback(
    async (eventId: string, isFavorite: boolean) => {
      await mutate((events) => {
        if (events) {
          const copiedEvents = deepCopy(events);
          const eventToUpdate = copiedEvents.find(
            (event) => event.id === eventId,
          );
          if (eventToUpdate) {
            eventToUpdate.isFavorite = isFavorite;
          }
          return copiedEvents;
        }
        return;
      }, false);
    },
    [mutate],
  );

  return (
    <div className="dark-mode">
      <HeroPage
        heroIllustration={ApiPageBanner}
        noContainer
        title="Events Explorer"
      >
        <Grid.Container columnGap rowGap>
          <Grid.Item
            className={style['activeFilterBox']}
            span={{ small: 'full', medium: 'full', large: [1, 4] }}
          >
            <Flex.Vertical space="small">
              <FormRow top="x-small">
                <Drawer
                  id="tagFilterDrawer"
                  title="Filters"
                  trigger={(props) => (
                    <Flex.Horizontal
                      {...props}
                      align="center"
                      className={style['filterTitle']}
                      gap="small"
                      justify="flex-start"
                    >
                      <Icon icon={filter} />
                      <P modifier="medium">Filters</P>
                    </Flex.Horizontal>
                  )}
                >
                  <div className={style['flyoutOptionWrapper']}>
                    <FormRow top="medium">
                      <EventFilter
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                      />
                    </FormRow>
                  </div>
                </Drawer>
              </FormRow>

              {isLarge && <P modifier="medium">Active filters</P>}
              <Tag.Group innerSpace="0" label="Filters" top="small">
                {activeFilters.length > 0 &&
                  activeFilters.map((tag) => (
                    <Tag
                      key={tag}
                      onClick={() =>
                        setActiveFilters(
                          activeFilters.filter(
                            (candidate) => candidate !== tag,
                          ),
                        )
                      }
                      variant="removable"
                    >
                      {tag}
                    </Tag>
                  ))}
              </Tag.Group>
            </Flex.Vertical>
          </Grid.Item>

          <Grid.Item span={{ small: 'full', large: [5, 12] }}>
            <FormRow bottom="small">
              <Input
                clear
                icon="loupe"
                on_change={({ value }) => setQuery(value)}
                placeholder="Search"
                stretch
                value={query}
              />
            </FormRow>
            <P modifier="medium" space={{ top: '1rem' }}>
              Showing {filteredEvents.length ?? 0} of {events?.length ?? 0}{' '}
              events
            </P>
            <Flex.Vertical
              align="stretch"
              gap="xx-small"
              style={{ margin: '1rem 0' }}
            >
              {error ? (
                <FormStatus stretch text="Failed to fetch events." />
              ) : isLoading ? (
                <ProgressIndicator />
              ) : filteredEvents.length === 0 ? (
                <InfoCard text="No events match the selected filters." />
              ) : (
                <EventList
                  events={filteredEvents}
                  onFavoriteChange={onFavoriteChange}
                />
              )}
            </Flex.Vertical>
          </Grid.Item>
        </Grid.Container>
      </HeroPage>
    </div>
  );
}

import { Checkbox, Flex, P } from '@dnb/eufemia';
import { Dispatch, SetStateAction } from 'react';

import { removeStringFromArray } from '@/utils/arrayUtils';

interface EventFilterProps {
  setActiveFilters: Dispatch<SetStateAction<string[]>>;
  activeFilters: string[];
}

export default function EventFilter({
  setActiveFilters,
  activeFilters,
}: EventFilterProps) {
  return (
    <Flex.Vertical>
      <P modifier="medium" space={{ bottom: 'x-small' }}>
        Event classification
      </P>
      <Checkbox
        checked={activeFilters.length === 0}
        label="All"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([])
            : setActiveFilters(removeStringFromArray(activeFilters, 'all'))
        }
      />
      <Checkbox
        checked={activeFilters.includes('DOMAIN')}
        label="Domain"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, 'DOMAIN'])
            : setActiveFilters(removeStringFromArray(activeFilters, 'DOMAIN'))
        }
      />
      <Checkbox
        checked={activeFilters.includes('EXPERIENCE')}
        label="Experience"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, 'EXPERIENCE'])
            : setActiveFilters(
                removeStringFromArray(activeFilters, 'EXPERIENCE'),
              )
        }
      />
      <Checkbox
        checked={activeFilters.includes('SYSTEM')}
        label="System"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, 'SYSTEM'])
            : setActiveFilters(removeStringFromArray(activeFilters, 'SYSTEM'))
        }
      />
    </Flex.Vertical>
  );
}
